import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Button } from '@material-ui/core';

const styles = theme => ({
  linkButton: {
    borderColor: '#fff',
    borderWidth: '2px',
    textTransform: 'none',
    fontFamily: 'Nunito',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '22px',
    '&:hover': {
      background: '#03215d',
    },
  },
});

const LinkButton = ({ classes, className, to, children }) => (
  <Button variant="outlined" component={Link} to={to} className={classNames(classes.linkButton, className)}>
    {children}
  </Button>
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(LinkButton);
