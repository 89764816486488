import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Paper } from '@material-ui/core';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import LinkButton from '../components/LinkButton';

import { Link } from "gatsby";

import { ReactComponent as UnicornIcon } from '../assets/unicorn.svg';

const styles = theme => ({
  unicornHeader: {
    margin: '10px auto 30px',
  },
  unicorn : {
    color: '#fff',
    width: '320px',
    height: 'auto',
    maxWidth: '100%',
  },
  jokeText: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '30px auto 0',
  },
  viewAll: {
    margin: '0 auto 40px',
  },
  link: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
  }
});

const jokes = [
  "Like a majority in the Commons for a particular form of Brexit, this page doesn't seem to exist.",
  "Like alternative arrangements to the backstop, there's nothing here.",
  "Like the Minister for Exiting the EU, this page seems to have gone missing.",
  "Oops, like David Cameron we seem to have made a mistake!",
  "We've compiled all of the details on our plans for the backstop and outlined our trade deals: (slide is blank)",
];

const FourZeroFour = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Page not Found "/>

    <Grid container justify="center" alignItems="center" direction="column"  className={classNames(classes.unicornHeader, className)}>
      <UnicornIcon className={classNames(classes.unicorn, className)} />
      <p className={classNames(classes.jokeText, className)} >
        { jokes[Math.floor(Math.random()*jokes.length)] }
      </p>
    </Grid>

    <Grid container justify="center" alignItems="center" className={classNames(classes.viewAll, className)}>
      <Link to="/" className={classNames(classes.link, className)}>Compare another issue</Link>
    </Grid>

  </Layout>
);

FourZeroFour.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

FourZeroFour.defaultProps = {
  data: {},
};

export default withRoot(withStyles(styles)(FourZeroFour));
